import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './pages/styles.css';
import './pages/login/login.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './pages/confirmation/confirmation.css';
import './pages/phone_confirmation/confirmation.css';
import './pages/schedule/schedule.css';
import './pages/registration/registration.css';
import './pages/phone/login.css';
import './pages/already_scheduled/scheduled.css';
import './pages/contact/contact.css';
import './pages/contact-error/contact.css';
import './pages/follow-up/contact.css';
import './pages/form-complete/contact.css';
import './pages/registration/registration.css';
import './component/contact-form/contact-form.css';
import './component/email-comp/google-score.css';
import './assets/comStyles.css';
import 'react-calendar/dist/Calendar.css';
import './custom-components/Calendar/styles.css';
import './component/custom-check-box/custom-check-box.css';
import { Spinner } from 'reactstrap';
import { jwtToken } from './context';

const Login = lazy(() => import('./pages/login'));
const ConfirmCode = lazy(() => import('./pages/confirmation'));
const ConfirmPhoneCode = lazy(() => import('./pages/phone_confirmation'));
const Schedule = lazy(() => import('./pages/schedule'));
const PersonalInfo = lazy(() => import('./pages/registration'));
const PhoneComp = lazy(() => import('./pages/phone'));
const Scheduled = lazy(() => import('./pages/scheduled'));
const AlreadyScheduled = lazy(() => import('./pages/already_scheduled'));
const Contact = lazy(() => import('./pages/contact'));
const ContactError = lazy(() => import('./pages/contact-error'));
const UnsupportedPostalCode = lazy(() => import('./pages/unsupported-postal-code'));
const FollowUp = lazy(() => import('./pages/follow-up'));
const FormComplete = lazy(() => import('./pages/form-complete'));
const SelectProduct = lazy(() => import('./pages/select-product'));
const EnterHomeYear = lazy(() => import('./pages/enter-home-year'));
const SelectHearAbout = lazy(() => import('./pages/select-hear-about'));
const EnterDetails = lazy(() => import('./pages/enter-details'));
const SelectStaff = lazy(() => import('./pages/select-staff'));

const App = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (jwtToken && jwtToken.trustedForm) {
        const loadTrustedFormScript = () => {
          const tfScript = document.createElement('script');
          const parentUrl = jwtToken.parentUrl || window.location.href;
          tfScript.src = `https://api.trustedform.com/trustedform.js?parent_url=${encodeURIComponent(parentUrl)}`;
          tfScript.async = true;
          document.body.appendChild(tfScript);
          return () => {
            document.body.removeChild(tfScript);
          };
        };

        loadTrustedFormScript();
        clearInterval(interval); // Clear the interval once the script is loaded
      } else if (jwtToken && jwtToken.token) {
        clearInterval(interval);
      }
    }, 200); // Check every 200ms

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div>
      <Router>
        <Suspense
          fallback={
            <div>
              {' '}
              <Spinner style={{ width: '2rem', height: '2rem' }} color="light">
                &nbsp;
              </Spinner>
            </div>
          }>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/phone" component={PhoneComp} />
            <Route exact path="/confirm-code" component={ConfirmCode} />
            <Route exact path="/confirm-phone-code" component={ConfirmPhoneCode} />
            <Route exact path="/select-product" component={SelectProduct} />
            <Route exact path="/set-home-year" component={EnterHomeYear} />
            <Route exact path="/select-hear-about" component={SelectHearAbout} />
            <Route exact path="/select-staff" component={SelectStaff} />
            <Route exact path="/person-info" component={PersonalInfo} />
            <Route exact path="/enter-details" component={EnterDetails} />
            <Route exact path="/schedule" component={Schedule} />
            <Route exact path="/appointment-status" component={Scheduled} />
            <Route exact path="/already-scheduled" component={AlreadyScheduled} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/follow-up" component={FollowUp} />
            <Route exact path="/form-complete" component={FormComplete} />
            <Route exact path="/contact-error" component={ContactError} />
            <Route exact path="/unsupported-postal-code" component={UnsupportedPostalCode} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
