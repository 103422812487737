import { makeRequest } from './services';
import { jwtToken } from './context';

export async function oneTimeAuth({ oneTimeToken, source, sourceUrl, sourceType }) {
  const { data, errorMessage } = await makeRequest('post', 'appointment/page/auth/one-time', {
    oneTimeToken,
    source,
    sourceUrl,
    sourceType
  });
  if (errorMessage) {
    return { errorMessage };
  }
  jwtToken.token = data.token;
  jwtToken.slots = data.slots;
  jwtToken.timeZone = data.timeZone;
  jwtToken.apptInfo = data.apptInfo;
  jwtToken.userInfo = data.userInfo;
  jwtToken.parentUrl = data.parentUrl;
  jwtToken.trustedForm = data.trustedForm;
  jwtToken.noPoBox = data.noPoBox;
  jwtToken.needsHomeYear = data.userInfo?.needsHomeYear;
  jwtToken.needsAddress = data.userInfo?.needsAddress;
  jwtToken.zipCode = data.userInfo?.zipCode;
  jwtToken.data = { ...data, oneTimeToken };
  return { styleData: data?.styleData };
}
