import axios from 'axios';

/**
 * Fetches a JSON file from your Bunny CDN via the given s3Key.
 * @param s3Key - The path/key to the JSON file (e.g., "data/example.json").
 * @returns A Promise that resolves to the parsed JSON object or undefined on failure.
 */
export async function fetchJsonFromBunny(s3Key) {
  const url = `https://cdn.heavyset.tech/${s3Key}`;

  try {
    const response = await axios.get(url, {
      responseType: 'json'
    });

    if (response.status === 200 && response.data) {
      return response.data;
    }
    return undefined;
  } catch (error) {
    console.error('Error fetching JSON from Bunny CDN:', error);
    return undefined;
  }
}
